import type { AsyncAppMounter } from "@/helpers/mount-component.types";

// const mountRootApp = async () =>
//   (await import("@/entrypoints/views/root/mount-apps")).mountRootApp;
// const mountPandasZap = async () =>
//   (await import("@/entrypoints/views/pandas/index/mount-apps")).mountZap;
// const mountPandasInfo = async () =>
// (await import("@/entrypoints/views/pandas/index/mount-apps")).mountPandasInfo;

const mountTracker = async () =>
  (await import("@/entrypoints/views/tracker/index/mount-apps")).mountTracker;

const mountTimeSheets = async () =>
  (await import("@/entrypoints/views/time_sheets/index/mount-apps")).mountView;

const mountClients = async () =>
  (await import("@/entrypoints/views/clients/index/mount-apps")).mountClients;

const mountIndex = async () =>
  (await import("@/entrypoints/views/admin/projects/mount-apps")).mountIndex;

const mountStaffIndex = async () =>
  (await import("@/entrypoints/views/admin/staff/mount-apps")).mountIndex;

const routes: {
  [routePath: string]: {
    [elementIdSelector: string]: AsyncAppMounter;
  };
} = {
  // "/": {
  //   "#root-view": mountRootApp,
  // },
  "/admin/clients": {
    "#clients": mountClients,
  },

  "/time_sheets": {
    "#time_sheets": mountTimeSheets,
  },

  "/tracker": {
    "#tracker": mountTracker,
  },
  // new route entry here
  "/admin/staff": {
    "#vue-root": mountStaffIndex,
  },
  "/admin/projects": {
    "#vue-root": mountIndex,
  },
  // "/pandas": {
  //   "#pandas-view": mountPandasInfo,
  //   "#lazy-load": mountPandasZap,
  // },
};

export const getVueComponents = (
  url: string
): { [elementIdSelector: string]: AsyncAppMounter } => {
  const pattern = /^\/((?:[^/]+\/)*[^/]+)\/\d+(\/\w+)?$/;
  const result = url.match(pattern);

  if (result !== null) {
    url = result[2] ? `/${result[1]}/:id${result[2]}` : `/${result[1]}/:id`;
  }

  return routes[url];
};
